.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.text-center {
    text-align: center;
}

.rounded {
    border-radius: 4px;
}

.bg-light {
    background-color: #f5f5f5;
}

.shadow {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 
                0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
                0px 1px 5px 0px rgba(0, 0, 0, 0.12); 
}

.py-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.gap-2 > * + * {
    margin-left: 8px;
}
