.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
}
.container > .content {
    font-size: 1.25rem;
    color: white;
}
.container > .message {
    font-size: 0.9rem;
    color: #efefef;
}