@import "./variables.scss";
@import "Common.module";
@import "./_colors.scss";

.main {
  height: 212px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

.title {
  display: inline-block;
  font-weight: bold;
  color: $text-gray;
  padding: 8px 0;
  font-size: 16px;
  text-align: left;
  width: 62px;
}

.contactFormLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  @media only screen and (max-width: 736px) {
    grid-template-columns: 1fr;
  }
}
.billingTitle {
  display: inline-block;
  font-weight: 800;
  color: $text-gray;
  padding: 1vh 0;
  font-size: 1.1rem;
  text-align: center;
  min-width: 100%;
  @media all and (min-width: 600px) {
    padding: 12px 13% 0 0;
    text-align: right;
  }
}

.sizeFix {
  width: 50%;
}

.info {
  display: inline-block;
  font-weight: 800;
  padding: 1vh 0;
  font-size: 1rem;
  max-width: 100%;
  text-overflow: ellipsis;
  text-align: center;
  color: $text_color;
  @include wordBreak;
  @media all and (min-width: 600px) {
    padding: 8px 0 8px 32px;
    text-align: left;
  }
}

.content {
  padding: 16px 16px 8px 16px;
  background-color: white;
}

.centerFix {
  display: flex;
  align-items: center;
}

.edit {
  background-color: $wiline_color;
  color: $orange;
  p {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    padding: 5px;
  }
  .icon {
    padding-left: 8px;
  }
  &:hover {
    color: white;
  }
}

.gridContainer {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
}

.billingContainer {
  max-width: 960px;
  margin: 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

.sizeFix {
  width: 64px !important;
  padding: 2px !important;
}

.buttonFix {
  display: flex;
  justify-content: flex-end;
  background-color: white;
}

.serviceAddressLoader {
  background-color: white;
  min-height: 160px;
}

// styles for changing contact info forms
.submitSuccess {
  text-align: center;
  margin-top: 16px;
}

.errorMessage {
  width: 100%;
  color: $wiline_color;
  font-size: 16px;
}

@media only screen and (min-width: 1000px) {
  .main {
    max-width: 460px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 672px) {
  .container {
    width: 400px;
    margin: 0 auto;
  }
}
