.page-loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    background-image: radial-gradient(circle, black, #052f59);
    z-index: -1;
}

.web {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 48px;
    font-weight: bold;
}

.spinner {
    width: 200px;
    height: 200px;
    border: 5px solid rgba(255, 255, 255, 0.2);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1.5s cubic-bezier(0.98, 0.26, 0.34, 0.93) infinite;
    position: absolute;
}

.loading-logo {
    width: 100%;
    height: 100%;
}

.letter {
    position: relative;
    top: 50%;
    height: 200px;
    width: 150px;
    vertical-align: middle;
    font-size: 110px;
    text-align: center;
    aspect-ratio: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.moving-line,
.moving-point {
    animation: movePoint 2s linear infinite;
}

@keyframes movePoint {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(9px, 2px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.message {
    position: fixed;
    top: 20%;
    bottom: 50%;
    color: white;
    font-size: 18px;
}