@import '../variables.scss';
@import '../colors.scss';
@import '../Common.module';

.container.loader {
  width: 100%;
  height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #00266c;
  font-size: 1.25rem;
}

.details {
    @include mui_card;
    @include containerShadow;
    box-sizing: border-box;
    display: grid;
    padding: 1rem;
    margin: 1rem 2vw;
    max-width: 100%;
    min-height: 198px;
  
    .detail-container {
      width: 100%;
      text-overflow: ellipsis;
      margin-bottom: 16px;
    }
  
    .title {
      color: $text-gray;
      font-size: 1rem;
    }
  
    .info {
      font-size: 1rem;
      font-weight: 600;
      color: $text-gray;
      margin-bottom: 3px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: nowrap;
      display: block;
      width: 100%;
      text-align: left;
      overflow: hidden;
    }
  
    .info:hover {
      overflow: visible;
      white-space: normal;
      width: auto;
    }
  }