.pb-4 {
  padding-bottom: 32px;
}

.mt-4 {
  margin-top: 32px;
}

.form-container {
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.bg-white {
  background-color: #ffffff;
}

.mb-3 {
  margin-bottom: 16px;
}

.response-message {
  padding-top: 10px;
  font-size: 1rem;
}
