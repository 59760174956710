.form-group {
    display: flex;
    align-items: center;
    height: 40px;
    padding-bottom: 30px;
}

.text-field {
    flex: 1;
    margin-right: 20px;

    .MuiInputBase-root {
        height: 40px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.card-heading {
    padding-left: 5px;
    padding-bottom: 10px;
    font-weight: 700 !important;
}

.icon-container {
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.cards {
    margin-top: 15px;
    border-radius: 12px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.1);
    min-width: 250px;
    max-width: 350px;
    min-height: 150px;
    margin-right: 20px !important; 

    .MuiPaper-rounded {
        border-radius: 10px !important;
    }

}


.add-button {
    height: 40px;
    width: 80px;
    margin-left: 10px;
}


.btn-div {
    margin-left: 10px;
}

.card-content {
    padding-bottom: 35px;

    &.MuiPaper-rounded {
        border-radius: 10px !important;
    }

    .normal-weight {
        font-weight: normal !important;
    }

    position: relative;

    .location-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #074481;
        color: white;
        padding: 8px;
        border-radius: 4px 4px 0 0;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right: 20px;
        min-width: 250px;
        max-width: 350px;
        min-height: 150px;

        .card-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 8px;
            padding-top: 15px;
            margin-bottom: 8px;
            min-width: 250px;
            max-width: 350px;
            min-height: 50px;

            .item-label {
                font-weight: bold;
                margin-bottom: 8px;
                margin-bottom: 4px;
            }

            .item-value {
                font-weight: bold;
            }
        }
    }

    .icon-container {
        position: absolute;
        right: 8px;
        display: flex;
    }
}