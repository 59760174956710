.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.card {
    &.selected {
        background-color: #074481; 
        color: white; 
    }

    &.shadow {
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12); // Material-UI shadow 3
    }
}

.text-center {
    text-align: center;
}

button {
    width: 100%;
}
