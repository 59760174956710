.order-now-nav {
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 3rem;
  color: white;
}

.nav-logo {
  display: block;
  width: max-content;
  height: max-content;
}

.nav-logo > img {
  min-height: 3rem;
}

.order-now-nav span {
  font-weight: bold;
}

.order-now-nav .left {
  align-items: center;
}

.order-now-nav .mid-links {
  column-gap: 2.5rem;
  padding: 0 2.5rem;
  justify-self: left;
}

.order-now-nav a {
  text-decoration: none;
}

.order-now-nav span {
  margin: auto !important;
}

.order-now-nav span a {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.right {
  column-gap: 2.5rem;
}

.bg-white {
  background: white;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.color-blue {
  color: #00266c;
}

.text-center {
  text-align: center;
}

.globe-div > div > canvas {
  border: none !important;
  outline: none !important;
}

.big-img {
  width: 60%;
  height: auto;
}

.choose-package > div {
  margin: auto !important;
}

.choose-package {
  padding: 20px !important;
}

.service-location {
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1rem;
}

.form-order-now select,
.form-order-now input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"] {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 4px;
  box-sizing: border-box;
}

.order-title {
  color: #fff !important;
}

.card-order {
  width: 140px;
  min-height: 50px;
  height: 56px;
  border: 2px solid #fff;
  border-width: 1px;
  border-radius: 4px;
  padding: 2px 4px;
  box-sizing: border-box;
  background: transparent;
  opacity: 0.7;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.card-order:hover {
  background: #fff;
  color: #000;
  border: 0;
  opacity: 1;
}

.order-now-loader {
  animation: spin infinite 1.5s linear;
}

.card-order-hover {
  width: 140px;
  min-height: 50px;
  height: 56px;
  border-width: 1px;
  border-radius: 4px;
  padding: 2px 4px;
  box-sizing: border-box;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  color: #000;
  border: 0;
  opacity: 1;
}

.order-button {
  background-color: white;
  color: black;
  border: 0;
  border-radius: 4px;
  padding: 1em 1em;
  width: 100%;
  max-width: 100%;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.order-button-disabled {
  background-color: white;
  color: black;
  border: 0;
  border-radius: 4px;
  padding: 1em 1em;
  width: 100%;
  max-width: 100%;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: not-allowed;
}

.order-now-bigger-text {
  margin-bottom: 2px;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
}

.order-now-smaller-text {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 600;
}

/* ---- particles.js container ---- */
#particles-js {
  position: absolute;
  width: 100%;
  /* height: 200vh; */
  /* background-color: #074481;*/
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

/* ---- stats.js ---- */
.count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.js-count-particles {
  font-size: 1.1em;
}

#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}

#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.count-particles {
  border-radius: 0 0 3px 3px;
}

.image-blurred-edge {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
  background: radial-gradient(closest-side, #ffffff1c, #ffffff0a, #00000008);
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
}

.contact-info input,
#creditCardFields input,
#achFields input {
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 1em 1em;
  background-color: hsla(0, 0%, 100%, 0.045);
  /* background: transparent; */
  color: white;
  border: 1px solid gray;
}

.contact-info input:focus,
#creditCardFields input:focus,
#achFields input:focus {
  border: 2px solid white;
}

.contact-info input::placeholder,
#creditCardFields input::placeholder,
#achFields input::placeholder {
  text-transform: uppercase;
  font-size: 14px;
}

#achFields select {
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 1em 1em;
  background-color: hsla(0, 0%, 100%, 0.045);
  border: 1px solid gray;
  text-transform: uppercase;
  font-size: 14px;
  color: white;
}

#achFields option {
  color: black;
}

.radio-group {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.custom-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.custom-col-7 {
  flex: 0 0 50%;
  text-align: left; /* Reset alignment */
  max-width: 50%;
}
.custom-col-5 {
  flex: 0 0 50%;
  /* padding-right: 20px; */
  text-align: left; /* Reset alignment */
  max-width: 50%;
}

@media (max-width: 950px) {
  .custom-col-7,
  .custom-col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  #creditCardFields {
    width: 100%;
  }
}

.form-order-now {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.choose-package {
  display: flex;
  justify-content: space-between;
}

.card-order,
.card-order-hover {
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}

.card-order-hover {
  background-color: #f0f0f0;
}

.input-group {
  display: flex;
  gap: 10px;
}

.input-group input {
  flex: 1;
}

.contact-info input {
  margin-bottom: 10px;
  width: 100%;
}

.error-text {
  color: red;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.order-button {
  background-color: #f0f0f0;
  color: #00266c;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.order-button-disabled {
  background-color: gray;
  color: white;
}

.order-now-loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
