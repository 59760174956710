@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .checkbox-wrapper-13 input[type="checkbox"] {
    --active: #275efe;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 15px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:disabled + label {
    cursor: not-allowed;
  }

  .checkbox-wrapper-13
    input[type="checkbox"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch) {
    width: 15px;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):after {
    opacity: var(--o, 0);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):checked {
    --o: 1;
  }

  .checkbox-wrapper-13 input[type="checkbox"] + label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 4px;
    top: 1px;
    transform: rotate(var(--r, 20deg));
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
}

.checkbox-wrapper-13 * {
  box-sizing: inherit;
}

.checkbox-wrapper-13 *:before,
.checkbox-wrapper-13 *:after {
  box-sizing: inherit;
}

.radio-input {
  appearance: none;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  border: 2px solid gray;
  transition: 0.2s all linear;
  position: relative;
  top: 3px;
  margin-left: 0px;
}

.radio-input:checked {
  border: 5px solid gray;
}

/* Padding for all sides */
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 4px !important;
}
.p-2 {
  padding: 8px !important;
}
.p-3 {
  padding: 16px !important;
}
.p-4 {
  padding: 24px !important;
}
.p-5 {
  padding: 48px !important;
}

/* Padding Top */
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-3 {
  padding-top: 16px !important;
}
.pt-4 {
  padding-top: 24px !important;
}
.pt-5 {
  padding-top: 48px !important;
}

/* Padding Bottom */
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pb-3 {
  padding-bottom: 16px !important;
}
.pb-4 {
  padding-bottom: 24px !important;
}
.pb-5 {
  padding-bottom: 48px !important;
}

/* Padding Left */
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 4px !important;
}
.pl-2 {
  padding-left: 8px !important;
}
.pl-3 {
  padding-left: 16px !important;
}
.pl-4 {
  padding-left: 24px !important;
}
.pl-5 {
  padding-left: 48px !important;
}

/* Padding Right */
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 4px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pr-3 {
  padding-right: 16px !important;
}
.pr-4 {
  padding-right: 24px !important;
}
.pr-5 {
  padding-right: 48px !important;
}

/* Padding X (Left & Right) */
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.px-3 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.px-4 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.px-5 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

/* Padding Y (Top & Bottom) */
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.py-4 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
