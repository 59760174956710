.container {
    display: flex;
    flex-direction: column;
    padding: 2vh 2vw;
}
.container.loader {
    width: 100%;
    height: calc(100vh - 66px);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00266c;
    font-size: 1.25rem;
}
.link {
    text-decoration: underline;
    color: #0066cc;
}
.link:hover {
    color: #2288ff;
}
.container .deleted { color: #220000; }
.container .new { color: #001122; }
.container .open { color: #002244; }
.container .resolved { color: #004400; }
.container .stalled { color: #444400; }
.container .deleted { color: #440000; }
.flex_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.flex_start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.container .more_button {
    background-color: #00266c;
    color: white;
}
.container .more_button:hover {
    background-color: #20468c;
}
