.container {
    width: 100%;
    height: 100%;
}
.w100 {
    width: 100%;
}
.h100 {
    min-height: calc(100vh - 127px);
}
.content {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.card {
    width: 100%;
    border-radius: 0.25rem;
    padding-bottom: 0.15rem;
}
.card .card_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1rem;
    align-items: center;
    width: 100%;
    padding: 0.75rem;
    color: white;
    background-color: #074481;
}
.card .card_content {
    padding: 1rem;
}
.card .card_button {
    border: 1px solid white;
    color: white;
    margin-right: 1.75rem;
    max-width: 128px;
}
.card .card_button:hover {
    background-color: rgba(255, 255, 255, 0.15);
}
.loader {
    color: #00266c;
    font-size: 1.1rem;
}
.flex_end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1.75rem;
}
.card .card_content .more_button {
    max-width: 128px;
    background-color: #00266c;
    color: white;
}
.card .card_content .more_button:hover {
    background-color: #20468c;
}