.image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
}

.close_icon {
  color: white;
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  cursor: pointer;
}

.image {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1rem;
  max-width: 100%;
  max-height: 100%;
  img {
    object-fit: contain;
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
  }
}
