.buy-now-container {
  .shadow-box {
      box-shadow: 0px 3px 6px #00000029;
      margin-bottom: 40px;
      padding: 24px;
      background-color: #fff;
  }

  .stepper-container {
      align-items: center;
  }

  .step-button {
    /*  background-color: #074481;
      color: #fff;*/
      height: 30px;
      width: 20px;
      justify-content: center;

      &:disabled {
          background-color: #b0bec5;
      }

      &:hover {
          background-color: #1976d2;
      }

      &.MuiButton-containedPrimary {
          margin-left: 8px;
          margin-right: 8px;
      }
  }

  .back-button {
      padding-right: 5px;
  }

  .next-button {
      padding-left: 5px;
  }

  .stepper {
      width: 100%;

      .MuiStepLabel-label {
          cursor: pointer;
      }

      .MuiStepLabel-iconContainer {
          cursor: pointer;
          background-color: #074481;
          border-radius: 50%;
          padding: 8px;

          &.active {
              background-color: orange;
          }
      }
  }
}

.stepperIcons {
  color: white;
  font-size: 20px;
}
