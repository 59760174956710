.add_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 2vw;
}

.add_button {
  border: 1px solid #00266c !important;
  color: #00266c !important;
  max-width: 170px;
}

.add_button:hover {
  background-color: rgba(0, 38, 108, 0.15);
}

.modal .title {
  margin-bottom: 0;
  padding-bottom: 0;
}
.modal .input {
  width: 100%;
  resize: vertical;
  outline: none;
  padding: 0.5rem;
}
