.comment_box {
  box-sizing: border-box;
  height: 60px;
  width: calc(100% - 1rem);
  margin: 0.5rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 0.5rem 4.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.5rem;
  .send_btn {
    max-width: 100px;
  }
}
.bottom-border-input {
  border: none;
  border-bottom: 2px solid #074481;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  resize: none;
  outline: none;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.bottom-border-input:focus {
  border-bottom: 2px solid #078181;
}

.input-container {
  margin: 10px 0;
}
.comment_icon{
  color:"#fd7e14d9";
  padding-right: 20px;
}
