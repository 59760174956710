@CHARSET "ISO-8859-1";

.displayNone {
    display: none !important;
}

.fa-spin {
    animation: spin infinite 1s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*****CSS Reset*****/
body,html,div,blockquote,img,label,p,h1,h2,h3,h4,h5,h6,pre,ul,ol,li,dl,dt,dd,form,a,fieldset,input,th,td
{
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
}
body {
    margin: 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #000000;
    text-align: justify;
    background-size: cover;
    overflow-x: hidden;
}

h1,h2{
    padding: 15px 0 10px 0;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-weight: bold;
    color: #074481;
}

h1 {
    font-size: 22px;
}

h2 {
    font-size: 20px;
}

.bg-wiline {
    background-color: #074481 !important;
  }

.wiline-blue{
    color: #074481 !important;
}

/* Footer */
#footer {
    grid-column: 1/-1;
    width: 80vw;
    /*height: 1vh;*/
    margin: 0 auto;
    padding: 10px 0;
    font-family: Arial, Helvetica, sans-serif;
    color: #006;
}

#footer p {
    margin: 0;
    line-height: normal;
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

#footer div.img-container {
    display: flex;
    justify-content: center;
}

#footer a {
    color: #FFFFFF;
}
input,textarea {
    font-size: 13px;
    border: 1pt solid #6481B0;
}

select {
    background: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}
#err {
    color: #993300;
    text-transform: none;
}

td {
    vertical-align: top;
}

/*************************************************** NEW STYLES ****************************************/
/**** Media Queries - Responsive Design *****/

@media all and (max-width: 950px) {
    .login-info-span {
        grid-column: 1/end;
        justify-self: start;
    }
}

@media all and (max-width: 570px) {
    #footer > p {
        text-align: center;
    }
}
