.service-card {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
  
    &.selected {
      background-color: #074481; 
      color: #ffffff;
  
      .MuiButton-containedPrimary {
        background-color: #ffc107; 
        color: #000000;
      }
    }
  }
  
  .mb-3 {
    margin-bottom: 16px;
  }
  
  .mt-4 {
    margin-top: 32px;
  }
  