.container {
  display: flex;
  flex-direction: column;
  padding: 2vh 2vw;
}

.statusCell {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.add_btn {
  width: fit-content;
}

.delete_btn {
  width: fit-content;
  background-color: #ff2323;
}

.approve_btn {
  width: fit-content;
  background-color: #ff2323;
}

.deny_btn {
  width: fit-content;
  background-color: #ff2323;
}

.input {
  margin: 0 !important;
  max-width: 240px;
  border-radius: 0.25rem;
  border: 1px solid #074481;
  padding: 0.5rem;
}

.input[aria-invalid=true] {
  border: 1px solid #ff2323;
  background-color: rgba(255, 0, 0, 0.05);
}

.input_error {
  font-size: 0.75rem;
  color: #dd2323;
}
