@import './_colors.scss';
@import './variables.scss';

.rightContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.count {
  display: flex;
  margin-right: 10px;
}

.list_item {
  font-size: 20px;
}

.history {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.toggle {
  color: black;
}

.logo {
  margin-top: 12px;
  @include center_all;

  img {
    width: 140px;
  }
}

.container {
  a {
    text-decoration: none;
    font-size: 20px;
    color: inherit;
  }
}

// Material-UI Nav
div.active {
  background-color: $orange;
  color: white;
  font-size: 20px;
}

div.active:hover {
  background-color: $orange;
  color: white;
}

.iconContainer {
  width: 30px;
  display: flex;
  justify-content: center;
}

// Material-UI Nav
div.activeSublink {
  text-decoration: underline;
  background-color: #5497e8;
}

.simpleNav {
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),
              0px 4px 5px 0px rgba(0,0,0,0.14),
              0px 1px 10px 0px rgba(0,0,0,0.12);
  display: flex;
  height: 64px;
  left: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1100;

  .logout {
      padding-left: 5vw;
      &:hover {
        color: $light_blue; 
      }
  }

  .backNav {
    padding-right: 24px;
  }
}

.simpleNavMultiple {
  justify-content: space-between;
}

.simpleNavSingle {
  justify-content: flex-start;
}
