.chat_wrapper {
  width: calc(100% - 1rem);
  overflow-y: scroll;
  padding-top: 1rem;
}

.chat_center {
  box-sizing: border-box;
  width: calc(100% - 1rem);
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loader {
    padding-top: 0.5rem;
    color: #074481;
    font-size: 1.1rem;
  }
}

.chat_wrapper.user_chatbox_input, .chat_center.user_chatbox_input {
  height: calc(100vh - 142px);
}

.chat_wrapper.admin, .chat_center.admin {
  height: calc(100vh - 66px);
}

.chat_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.ant-timeline {
  width: calc(100% - 2rem);
}

.ant-timeline-item {
  box-sizing: border-box;
  min-height: 68px;
}

.ant-timeline-item-tail, .ant-timeline-item-head {
  inset-inline-start: 144px !important;
}

.ant-timeline-item-content {
  width: unset !important;
  inset-inline-start: 160px !important;
  max-width: calc(100% - 160px);
}

.ant-timeline-item-label {
  width: fit-content !important;
  padding-right: 0.5rem;
  // inset-block-start: -16px !important;
}

.chat_event_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  .date {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
    font-size: 0.75rem;
    color: #a0a0a0;
  }

  .event {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
  
    .line {
      flex-grow: 1;
      height: 1px;
      background-color: #d0d0d0;
    }
    .text {
      font-size: 0.875rem;
      font-weight: 500;
      color: #707070;
    }
  }
}

.chat_customer_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.25rem 0;
  .chatbox_wrapper {
    display: flex;
    flex-direction: column;
    .chatbox {
      display: flex;
      flex-direction: column;
      border-left: 5px solid #078181;
      background-color: white;
      -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
      font-size: 0.875rem;
      padding: 0.5rem;
      text-wrap: wrap;
      min-width: 50vw;
      min-height: 15vh;
      .title_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
        .date {
          font-size: 0.875rem;
          color: #a0a0a0;
          padding: 5px;
        }
        .title {
          font-size: 0.875rem;
          font-weight: 600;
          padding: 5px;
        }
      }
      .line {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        font-size: 12px;
        font-style: italic;
      }
    }
  }
}

.chat_wiline_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.25rem 0;
  .chatbox_wrapper {
    display: flex;
    flex-direction: column;
    .chatbox {
      display: flex;
      flex-direction: column;
      border-left: 5px solid #074481;
      background-color: white;
      -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
      padding: 0.5rem;
      font-size: 0.875rem;
      text-wrap: wrap;
      min-width: 50vw;
      min-height: 15vh;
      .title_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
        .date {
          font-size: 0.875rem;
          color: #a0a0a0;
        }
        .title {
          font-size: 0.875rem;
          font-weight: 600;
          
        }
      }
      .line {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        font-size: 12px;
        font-style: italic;
      }
    }
  }
}

.fill {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.45rem;
  color: white;
  border-radius: 50%;
}

.fill.wiline { background-color: #074481; }
.fill.cyan { background-color: #078181; }

.quote_block {
  width: 2px;
  margin-right: 0.5rem;
}

.quote_toggle {
  text-decoration: underline;
  color: #074481;
  transition: color ease 0.3s;
  font-size: 0.75rem;
  cursor: pointer;
}
.quote_toggle:hover {
  color: #20468c;
}
.wiline-hr{
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}
.fancy-hr-container {
  text-align: center;
  margin: 2px 0; 
}

.fancy-hr-line {
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.fancy-hr-line .line {
  flex: 1;
  height: 2px;
  background: linear-gradient(to right, #078181, #074481, #078181); 
}

.fancy-hr-line .arrow-left,
.fancy-hr-line .arrow-right {
  font-size: 1.2em;
  color: #078181;
  margin: 0 30px;
}

.text {
  font-size: 14px;
  color: #a0a0a0;
}

.image_box {
  display: flex;
  overflow: hidden;
  cursor: pointer;
  img {
    object-fit: cover;
    width: inherit;
    height: inherit;
  }
  height: 180px;
  width: 291px;
  align-items: center;
  justify-content: center;
  background: transparent;
  background-clip: border-box;
  margin: 0;
  padding: 0;
  border-radius: 0.5rem;
  // -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.chat_footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 2px;
  align-items: flex-end;
}
