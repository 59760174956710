@import './variables.scss';
@import './colors.scss';
@import './Common.module';

.details {
  @include mui_card;
  @include containerShadow;
  // grid-column: 1/-1;
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 1vw;
  grid-template-columns: 50% 50%;
  grid-row-gap: 1vh;
  padding: 2vh 2vw;
  max-width: 100%;
  min-height: 198px;

  .detailContainer {
    width: 100%;
    text-overflow: ellipsis;
  }

  .title {
    color: $text-gray;
    font-size: 1rem;
  }

  .info {
    font-size: 1rem;
    font-weight: 600;
    color: $text-gray;
    margin-bottom: 3px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    display: block;
    width: 100%;
    text-align: left;
    overflow: hidden;
  }

  .info:hover {
    overflow: visible;
    white-space: normal;
    width: auto;
  }

  .grid_button {
    align-self: end;
    background-color: $orange;
    grid-column: 2 / 3;
    grid-row: 5/ 7;
    height: 36px;
    font-size: 0.9rem;
    font-weight: bold;
    width: 180px;
  }

  @media screen and (max-width: $large) {
    grid-column: 1/-1;
  }

  @media only screen and (max-width: 512px) {
    grid-template-columns: 100%;
    grid-row-gap: 2vh;

    .buttonAlign {
      margin: auto;
    }

    .detailContainer {
      padding-left: 3vw;
    }
  }
}

.shadow {
  @include containerShadow;
}

.buttonAlign {
  margin: auto 0 0;
}

.gridFix {
  width: 100%;
  background-color: $light_blue_bg;
  margin: 0 auto;
  height: 150vh;
}

.loaderPosition {
  position: relative;
  left: 50%;
}

.cards {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: repeat(12, 1fr);
  align-items: start;
  grid-template-rows: repeat(3, minmax(80px, 1fr));

  @media only screen and (max-width: 380px) {
    grid-template-rows: auto auto auto;
    padding-bottom: 80px;
    /* prevents recaptcha from blocking card */
  }
}

// @media (max-width: $drawer-break) {
//   .container {
//     width: 100%;
//   }

//   grid-column: 1/-1;
//   max-width: 100vw;
//   min-height: 100%;
//   display: grid;
//   padding-right: 2vw;
//   justify-content: center;
// }

.clearBottom {
  padding-bottom: 70px;
}

.accDetails {
  max-width: 976px;
  margin: 2vh auto;
}

// Width and margin for call details
@mixin callDetailWidth {
  margin: 0 auto 2vw;
  padding: 0 12px 28px 12px;
  width: 40vw;
}

// Call Detail Record Form
.cdrForm {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 760px;
  padding: 16px;
}

// Error container for call data form
.errorContainer {
  padding-top: 14px;
  width: 100%;
}

// Container for Call Detail Record page
.callContainer {
  a {
    text-decoration: none;
    color: inherit;
  }

  .csv {
    color: $wiline_blue;
    position: relative;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // @media only screen and(max-width: 521px) {
    //   justify-content: center;
    // }
  }

  .cdrButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    flex-wrap: nowrap;
  }

  .rdyMsg {
    margin-top: 24px;
    text-align: center;
    width: 47%;
    min-width: 150px;

    @media only screen and (max-width: 521px) {
      width: 100%;
    }
  }

  .downloadButton {
    margin-top: 2vh;
    width: 47%;
    min-width: 150px;

    @media only screen and (max-width: 521px) {
      width: 100%;
    }
  }

  .spin {
    @include center_all;
    font-size: 1rem;
  }


  .callError {
    @include callDetailWidth;
    color: $red;

    @media (max-width: 599px) {
      text-align: center;
      width: 100%;
    }
  }
}