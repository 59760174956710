.alert-dismissible-container {
    position: absolute !important;
    top: 5rem;
    left: 0;
    right: 0;
    z-index: 1100;
}

.alert-dismissible-container>.alert-dismissible {
    height: fit-content !important;
    width: fit-content !important;
}

.alert-dismissible-container-survey {
    position: absolute !important;
    top: -5rem !important;
    left: 0;
    right: 0;
}

.alert-dismissible-container-survey-acl {
    position: absolute !important;
    top: -9rem !important;
    left: 0;
    right: 0;
}